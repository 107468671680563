/* eslint-disable import/no-anonymous-default-export */
import axios from "../utils/axios";
import ApiTokenSupport from "./token.support";
import { RegisterData } from "../interfaces/user";
import { HubspotContactData } from "../components/modals/LoginRegisterModal/LoginRegisterModal";

class UserApi extends ApiTokenSupport {
  async login(email: string, password: string): Promise<string> {
    const resp = await axios.post<string>("/api/mortgageNotes/user/login", {
      email,
      password,
    });
    return resp.data;
  }

  async register(
    data: RegisterData
  ): Promise<{ success: boolean; token: string; error: string }> {
    const resp = await axios.post<{
      success: boolean;
      token: string;
      error: string;
    }>("/api/mortgageNotes/user/register", {
      ...data,
    });
    return resp.data;
  }

  async recoverPassword(email: string): Promise<boolean> {
    const resp = await axios.post<boolean>(
      `/api/mortgageNotes/admin/password-recovery`,
      {
        email,
      }
    );
    return resp.data;
  }

  /**
   * Reset password
   * @param email
   * @returns
   */
  async resetPassword(newPassword: string, token: string): Promise<boolean> {
    const resp = await axios.post<boolean>(
      `/api/mortgageNotes/admin/password-reset`,
      {
        newPassword,
        token,
      }
    );
    return resp.data;
  }

  async setPassword(password: string, token: string): Promise<string> {
    const resp = await axios.post<string>(
      `/api/mortgageNotes/admin/set-password`,
      {
        password,
        token,
      }
    );
    return resp.data;
  }

  async checkHubspotEmail(email: string): Promise<any> {
    const resp = await axios.post(
      `/api/mortgageNotes/user/check-hubspot-email`,
      { email }
    );
    return resp.data;
  }

  async createHubspotContact(
    data: HubspotContactData & { email: string }
  ): Promise<any> {
    const resp = await axios.post(
      `/api/mortgageNotes/user/create-hubspot-contact`,
      data
    );
    return resp.data;
  }
}

export default new UserApi();
