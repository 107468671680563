import React from "react";
import Note from "../pages/Note";
import { useParams } from "react-router-dom";

interface NoteGuardProps {
  props?: any;
}

const NoteGuard = ({ props }: NoteGuardProps) => {
  
  const { noteID } = useParams();

  if (!noteID) {
    return <></>;
  }

  return (
    <>
      <Note noteID={Number(noteID)} />
    </>
  );
};

export default NoteGuard;
