import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import MortgageNote, {
  FetchNotesParams,
} from "../interfaces/mortgageNote.redux.interface";
import { NavigateFunction } from 'react-router-dom';

interface MortgageNotesState {
  notes: MortgageNote[];
  status: "idle" | "loading" | "failed";
  hasMore: boolean;
  filters: FetchNotesParams;
  searchQuery: string;
}

const initialState: MortgageNotesState = {
  notes: [],
  status: "idle",
  hasMore: true,
  filters: {
    addressState: [],
    targetMin: undefined,
    targetMax: undefined,
    loanMin: undefined,
    loanMax: undefined,
    status: ["Available", "Avaliable"],
    offset: 0,
    limit: 10,
  },
  searchQuery: "",
};

export const fetchMortgageNotes = createAsyncThunk(
  "mortgageNotes/fetchMortgageNotes",
  async (filters: FetchNotesParams = {}) => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/mortgageNotes/note/fetch`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...filters,
          status: ["Available", "Avaliable"],
          limit: filters.limit || 10,
          offset: filters.offset || 0,
        }),
      }
    );
    const data = await response.json();
    return data as MortgageNote[];
  }
);

// Add a helper function to update URL params
const updateURLParams = (filters: Partial<FetchNotesParams>, navigate: NavigateFunction) => {
  const queryParams = new URLSearchParams();

  if (filters.addressState?.length) 
    queryParams.set("addressState", filters.addressState.join(","));
  if (filters.targetMin !== undefined) 
    queryParams.set("targetMin", filters.targetMin.toString());
  if (filters.targetMax !== undefined) 
    queryParams.set("targetMax", filters.targetMax.toString());
  if (filters.loanMin !== undefined) 
    queryParams.set("loanMin", filters.loanMin.toString());
  if (filters.loanMax !== undefined) 
    queryParams.set("loanMax", filters.loanMax.toString());
  if (filters.status?.length) 
    queryParams.set("status", filters.status.join(","));
  if (filters.searchQuery) 
    queryParams.set("search", filters.searchQuery);

  navigate({ search: queryParams.toString() });
};

export const updateFiltersAndFetch = createAsyncThunk(
  "mortgageNotes/updateFiltersAndFetch",
  async (
    payload: { 
      filters: Partial<FetchNotesParams>; 
      navigate: NavigateFunction 
    }, 
    { dispatch, getState }
  ) => {
    const state = getState() as { mortgageNotes: MortgageNotesState };
    const updatedFilters = {
      ...state.mortgageNotes.filters,
      ...payload.filters,
      offset: 0,
    };

    // Update URL parameters
    updateURLParams(updatedFilters, payload.navigate);

    // Update Redux state and fetch data
    dispatch(setFilters(updatedFilters));
    return dispatch(fetchMortgageNotes(updatedFilters)).unwrap();
  }
);

export const handleSearchAndFetch = createAsyncThunk(
  "mortgageNotes/handleSearchAndFetch",
  async (query: string, { dispatch, getState }) => {
    const state = getState() as { mortgageNotes: MortgageNotesState };

    // Only update if the search query has actually changed
    if (state.mortgageNotes.searchQuery !== query) {
      const updatedFilters: FetchNotesParams = {
        addressState: [],
        targetMin: undefined,
        targetMax: undefined,
        loanMin: undefined,
        loanMax: undefined,
        status: ["Available", "Avaliable"],
        offset: 0,
        limit: 10,
      };

      // Set search query separately since it's not part of FetchNotesParams
      dispatch(setFilters(updatedFilters));
      dispatch(setSearchQuery(query));
      return dispatch(
        fetchMortgageNotes({ ...updatedFilters, searchQuery: query })
      ).unwrap();
    }
    return Promise.resolve([]);
  }
);

export const mortgageNotesSlice = createSlice({
  name: "mortgageNotes",
  initialState,
  reducers: {
    setMortgageNotes: (state, action: PayloadAction<MortgageNote[]>) => {
      state.notes = action.payload;
    },
    addMortgageNotes: (state, action: PayloadAction<MortgageNote>) => {
      state.notes.push(action.payload);
    },
    appendMortgageNotes: (state, action: PayloadAction<MortgageNote[]>) => {
      state.notes = [...state.notes, ...action.payload];
    },
    setLoadingStatus: (state) => {
      state.status = "loading";
    },
    setIdleStatus: (state) => {
      state.status = "idle";
    },
    setFailedStatus: (state) => {
      state.status = "failed";
    },
    setFilters: (state, action: PayloadAction<FetchNotesParams>) => {
      state.filters = action.payload;
    },
    setSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMortgageNotes.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchMortgageNotes.fulfilled, (state, action) => {
        if (action?.meta?.arg?.offset === 0) {
          state.notes = action.payload;
        } else {
          state.notes = [...state.notes, ...action.payload];
        }
        state.status = "idle";
        state.hasMore =
          action.payload.length === (action?.meta?.arg?.limit || 10);
      })
      .addCase(fetchMortgageNotes.rejected, (state) => {
        state.status = "failed";
      });
  },
});

// Export actions for use in components
export const {
  setMortgageNotes,
  addMortgageNotes,
  appendMortgageNotes,
  setLoadingStatus,
  setIdleStatus,
  setFailedStatus,
  setFilters,
  setSearchQuery,
} = mortgageNotesSlice.actions;

export default mortgageNotesSlice.reducer;
