import React from "react";
import useAuth from "../../hooks/useAuth";
import {
  faBookmark,
  faHouse,
  faIdCard,
  faNotes,
  faRightFromBracket,
} from "@fortawesome/pro-regular-svg-icons";
import { faBars, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

interface PrivateNavProps {
  props?: any;
}

const PrivateNav = ({ props }: PrivateNavProps) => {
  const { logout } = useAuth();

  const navigate = useNavigate();

  const handleAllNotes = () => {
    return navigate("/");
  };

  const handleSavedNotes = () => {
    return navigate("/saved-notes");
  };

  const handlePostNote = () => {
    return navigate("/post-note");
  };

  const handleMyNotes = () => {
    return navigate("/my-notes");
  };

  const handleMyInfo = () => {
    return navigate("/my-info");
  };

  const handleLogout = () => {
    return logout();
  };
  return (
    <>
      <div className="flex-none gap-2">
        <div className="dropdown dropdown-end">
          <div tabIndex={0} role="button" className="btn btn-accent rounded-lg">
            <div className="w-full h-full flex items-center justify-center">
              <FontAwesomeIcon icon={faBars} className=" text-brown text-xl" />
            </div>
          </div>
          <ul
            tabIndex={0}
            className="menu menu-sm dropdown-content bg-white rounded-lg  mt-3 w-52  shadow z-50 gap-1 text-main-gray border-[1px] border-gray-200"
          >
            <li className="">
              <button
                onClick={handleAllNotes}
                className="flex flex-row gap-2 w-full text-brown  hover:bg-gray-200 rounded-[4px]"
              >
                <FontAwesomeIcon icon={faHouse} className="w-4" />
                <span className="font-semibold">All Notes</span>
              </button>
            </li>
            <li className="">
              <button
                onClick={handleSavedNotes}
                className="flex flex-row gap-2 w-full text-brown  hover:bg-gray-200 rounded-[4px]"
              >
                <FontAwesomeIcon icon={faBookmark} className="w-4" />
                <span className="font-semibold">Saved Notes</span>
              </button>
            </li>
            {/* <li className="">
              <button
                onClick={handlePostNote}
                className="flex flex-row gap-2 w-full text-brown  hover:bg-gray-200 rounded-[4px]"
              >
                <FontAwesomeIcon icon={faPlus} className="w-4" />
                <span className="font-semibold">Post Note</span>
              </button>
            </li> */}
            {/* <li className="">
              <button
                onClick={handleMyNotes}
                className="flex flex-row gap-2 w-full text-brown  hover:bg-gray-200 rounded-[4px]"
              >
                <FontAwesomeIcon icon={faNotes} className="w-4" />
                <span className="font-semibold">My Notes</span>
              </button>
            </li> */}
            {/* <li className="">
              <button
                onClick={handleMyInfo}
                className="flex flex-row gap-2 w-full text-brown  hover:bg-gray-200 rounded-[4px]"
              >
                <FontAwesomeIcon icon={faIdCard} className="w-4" />
                <span className="font-semibold">My Info</span>
              </button>
            </li> */}
            <li className="">
              <button
                onClick={handleLogout}
                className="flex flex-row gap-2 w-full text-brown  hover:bg-gray-200 rounded-[4px]"
              >
                <FontAwesomeIcon icon={faRightFromBracket} className="w-4" />
                <span className="font-semibold">Logout</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default PrivateNav;
