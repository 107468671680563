import React, { useState, useEffect, useRef } from "react";
import MortgageNote from "../../redux/interfaces/mortgageNote.redux.interface";
import addCommas from "../../helpers/addCommas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBookmark,
  faHouse,
  faLocationDot,
} from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Note from "../../interfaces/mortgageNote";
import { calculateMonthlyReturn } from "../../helpers/calculateMonthyReturn";
import useAuth from "../../hooks/useAuth";
import { notesApi } from "../../api";

interface NoteCardProps {
  note: MortgageNote;
  status: string;
  hideRibbon?: boolean;
  fetchSavedNotes?: (showLoading?: boolean) => void;
}

const NoteCard = ({
  note,
  status,
  hideRibbon = false,
  fetchSavedNotes,
}: NoteCardProps) => {
  const [cardWidth, setCardWidth] = useState(0);

  const cardRef = useRef<HTMLDivElement>(null);

  const [isHovering, setIsHovering] = useState(false);

  const [hasScrolled, setHasScrolled] = useState(false);

  const highlightRef = useRef<HTMLParagraphElement>(null);

  const containerRef = useRef<HTMLDivElement>(null);

  const { currentUser } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (!cardRef.current) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setCardWidth(entry.contentRect.width);
      }
    });

    resizeObserver.observe(cardRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    const highlightElement = highlightRef.current;
    const containerElement = containerRef.current;
    if (!highlightElement || !containerElement) return;

    const isTextOverflowing =
      highlightElement.scrollWidth > containerElement.clientWidth;

    if (!isTextOverflowing || hasScrolled) {
      highlightElement.style.transform = "translateX(0)";
      return;
    }

    let animationId: number;
    const scrollDistance =
      highlightElement.scrollWidth - containerElement.clientWidth + 80;
    const duration = scrollDistance * 9;

    const step = (timestamp: number) => {
      if (!animationId) {
        animationId = timestamp;
      }
      const elapsed = timestamp - animationId;
      const progress = Math.min(elapsed / duration, 1);

      highlightElement.style.transform = `translateX(-${
        progress * scrollDistance
      }px)`;

      if (progress < 1) {
        requestAnimationFrame(step);
      } else {
        setHasScrolled(true);
      }
    };

    if (isHovering && !hasScrolled) {
      requestAnimationFrame(step);
    }

    return () => {
      if (animationId) cancelAnimationFrame(animationId);
    };
  }, [isHovering, hasScrolled]);

  const handleMouseEnter = () => {
    setIsHovering(true);
    setHasScrolled(false);
  };

  const {
    street_number,
    city,
    state,
    postal_code,
    highlight,
    loan_amount,
    loan_to_value,
    loan_term,
    interest_rate,
    type,
    property_image,
    status: noteStatus,
  } = note;

  const buildAddress = (): string =>
    currentUser
      ? `${street_number} ${city}, ${state} ${postal_code}`
      : `${city}, ${state}`;

  const statusMapping = () => {
    switch (noteStatus.toLowerCase()) {
      case "achieved":
        return "bg-green-500 text-white";
      case "avaliable":
        return "bg-accent text-brown";
      case "defaulted":
        return "bg-red-500 text-white";
      case "funded":
        return "bg-blue-500 text-white";
      case "non performing":
        return "bg-orange-500";
      case "paid off":
        return "bg-green-500 text-black";
      case "reo":
        return "bg-purple-500 text-white";
      case "turn key investments":
        return "bg-teal-500 text-white";
      default:
        return "bg-gray-200 text-black";
    }
  };

  const getBadgeClass = () => {
    if (cardWidth < 300) return "text-xs px-1.5 py-0.75";
    if (cardWidth < 400) return "text-sm px-1.5 py-0.75";
    if (cardWidth < 500) return "text-base px-2 py-1";
    return "text-lg px-2.5 py-1.5";
  };

  const getIconClass = () => {
    if (cardWidth < 300) return "w-3";
    if (cardWidth < 400) return "w-4";
    if (cardWidth < 500) return "w-5";
    return "w-6";
  };

  const onNoteClick = (note: Note) => {
    navigate(`/notes/${note.id}`);
  };

  const handleSaveNote = async (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!currentUser) return;
    await notesApi.toggleSavedNote(note.id, currentUser.id);
    fetchSavedNotes?.(false);
  };

  return (
    <div
      ref={cardRef}
      className="card bg-white shadow-xl hover:cursor-pointer hover:scale-[1.01] ease-in-out transition-all duration-150 flex flex-col h-full w-full sm:w-11/12 md:w-5/6 lg:w-full xl:w-11/12 2xl:w-5/6 mx-auto"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => setIsHovering(false)}
      onClick={() => onNoteClick(note)}
    >
      <figure className="relative overflow-hidden h-40 sm:h-48 md:h-56 lg:h-64 xl:h-72 2xl:h-80">
        <img
          src={property_image}
          alt={buildAddress()}
          className="w-full h-full object-cover"
        />
        <div
          className={`absolute bg-black text-white rounded-md font-bold left-4 top-3 flex items-center justify-start gap-1 ${getBadgeClass()}`}
        >
          <FontAwesomeIcon icon={faHouse} className={getIconClass()} />
          {cardWidth >= 300 ? (
            <span>{type?.toUpperCase() || "SINGLE FAMILY RESIDENCE"}</span>
          ) : (
            <span>
              {(type?.toUpperCase() || "SINGLE FAMILY RESIDENCE").slice(0, 3)}
            </span>
          )}
        </div>
        {hideRibbon ? (
          isHovering &&
          currentUser && (
            <div className="absolute -right-[12px] top-[8px] h-16 w-16">
              <button
                className=" btn btn-accent rounded-[12px]"
                onClick={handleSaveNote}
              >
                <FontAwesomeIcon icon={faBookmark} />
              </button>
            </div>
          )
        ) : (
          <div className="absolute right-0 top-0 h-16 w-16">
            <div
              className={`absolute transform rotate-45 text-center font-bold py-1 right-[-35px] top-[32px] w-[170px] ${statusMapping()}`}
            >
              {noteStatus?.toUpperCase() || "AVAILABLE"}
            </div>
          </div>
        )}
      </figure>
      <div className="card-body flex-grow flex flex-col justify-between p-3 sm:p-4 md:p-5 lg:p-6">
        <div>
          <div className="text-brown flex gap-1.5 items-center justify-start mb-2">
            <FontAwesomeIcon icon={faLocationDot} className="" />
            <span>{buildAddress()}</span>
          </div>
          <div ref={containerRef} className="relative overflow-hidden mb-4">
            <p
              ref={highlightRef}
              className="text-brown whitespace-nowrap transition-transform duration-300 ease-linear"
              style={{ paddingRight: "100px" }}
            >
              {highlight}
            </p>
          </div>
        </div>
        <div>
          <div className="flex items-center justify-between mb-4">
            <div className="flex flex-col columns-1 justify-start items-start">
              <div className="text-secondary font-bold">LOAN AMT</div>
              <div className="text-brown font-bold">{`$${addCommas(
                loan_amount
              )}`}</div>
            </div>
            <div className="flex flex-col columns-1 justify-start items-start">
              <div className="text-secondary font-bold">TARGETED ROR</div>
              <div className="text-brown font-bold">{`${
                interest_rate || "?"
              }%`}</div>
            </div>
            <div className="flex flex-col columns-1 justify-start items-start">
              <div className="text-secondary font-bold">LTV</div>
              <div className="text-brown font-bold">{`${loan_to_value}%`}</div>
            </div>
          </div>
          <div className="h-[1px] bg-brown rounded-full mb-4"></div>
          <div className="flex items-center justify-between">
            <div className="text-secondary font-bold">
              TARGETED MONTHLY RETURN
            </div>
            <div className="text-brown font-bold">{`$ ${calculateMonthlyReturn(
              loan_amount,
              interest_rate,
              loan_term
            )}`}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoteCard;
