import React, { useEffect, useState } from "react";
import { notesApi } from "../api";
import { FullNote } from "../interfaces/mortgageNote";
import { faArrowLeft, faLocationDot } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropertyImageCarousel from "../components/note/PropertyImageCarousel";
import ActionButton from "../components/buttons/ActionButton";
import NoteInfoCard from "../components/note/NoteInfoCard";
import { Highlight } from "../interfaces/mortgageNote";
import addCommas from "../helpers/addCommas";
import { calculateMonthlyReturn } from "../helpers/calculateMonthyReturn";
import { useNavigate } from "react-router-dom";
import useModal from "../hooks/useModal";
import useAuth from "../hooks/useAuth";
import LoginRegisterModal from "../components/modals/LoginRegisterModal/LoginRegisterModal";
import SellerDetailsModal from "../components/modals/SellerDetailsModal";
import LoadingTable from "../components/loading/LoadingTable";
import SavedNoteButton from "../components/buttons/SavedNoteButton";

interface NoteProps {
  noteID: number;
}

const Note = ({ noteID }: NoteProps) => {
  const [note, setNote] = useState<FullNote>();

  const navigate = useNavigate();

  const { currentUser } = useAuth();

  const [isFetchingNote, setIsFetchingNote] = useState<boolean>(true);

  const { openModalWith } = useModal();

  useEffect(() => {
    const fetchNote = async () => {
      try {
        setIsFetchingNote(true);
        const note = await notesApi.fetchNote(noteID, currentUser?.id);
        setNote(note);
      } catch (err: any) {
        console.error(err);
      } finally {
        setIsFetchingNote(false);
      }
    };

    fetchNote();
  }, [noteID]);

  if (!note) {
    return <></>;
  }

  const {
    street_number,
    city,
    state,
    postal_code,
    loan_type,
    loan_amount,
    loan_term,
    appraised_value,
    loan_to_value,
    purchase_price,
    interest_rate,
    down_payment,
    lien_position,
    funds_heald_in_escro,
    type,
    exit_strategy,
    use_of_proceeds,
    maturity_date,
    average_credit,
    average_balanace,
    repeat_borrower,
    previous_cogo,
    bedrooms,
    bathrooms,
    sqrfeet,
    highlights,
  } = note;

  const buildAddress = (): string =>
    currentUser
      ? `${street_number} ${city}, ${state} ${postal_code}`
      : `${city}, ${state}`;

  const handleDownloadClick = () => {
    openModalWith({
      title: "",
      body: <SellerDetailsModal note={note} />,
      hideButtons: true,
    });
  };

  const handleRegister = () => {
    openModalWith({
      title: "",
      body: <LoginRegisterModal initialSlide={1} note={note} />,
      hideButtons: true,
    });
  };

  const formatDate = (dateString: Date | string): string => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US");
  };

  const overviewRows = [
    { label: "LOAN TYPE", value: loan_type },
    { label: "LIEN POSITION", value: lien_position },
    { label: "LOAN AMOUNT", value: `$${addCommas(loan_amount)}`, loan_amount },
    { label: "PURCHASE PRICE", value: `$${addCommas(purchase_price || 0)}` },
    { label: "MARKET VALUE", value: `$${addCommas(appraised_value || 0)}` },
    {
      label: "DOWN PAYMENT",
      value: `$${addCommas(down_payment || 0)}`,
      down_payment,
    },
    { label: "ARV LTV", value: `${loan_to_value}%` },
    { label: "LOAN TERM", value: `${loan_term} Months` },
    {
      label: "TARGETED MONTHLY RETURN",
      value: `$${calculateMonthlyReturn(
        loan_amount,
        interest_rate,
        loan_term
      )}`,
    },
    {
      label: "AMOUNT HELD FOR REHAB",
      value: `$${addCommas(funds_heald_in_escro || 0)}`,
    },
    { label: "USE OF PROCEEDS", value: use_of_proceeds },
    { label: "EXIT STRATEGY", value: exit_strategy },
    { label: "MATURITY DATE", value: formatDate(maturity_date) },
    {
      label: "TARGETED RATES OF RETURN",
      value: `${interest_rate ? `${interest_rate}% ` : "-"}`,
    },
    { label: "AVERAGE CREDIT", value: average_credit ?? "-" },
    { label: "AVERAGE BANK BALANCE", value: `$${average_balanace ?? 0}` },
    { label: "REPEAT BORROWER", value: repeat_borrower ? "Yes" : "No" },
    {
      label: "PREVIOUS LOAN WITH COGO CAPITAL",
      value: previous_cogo ? "Yes" : "No",
    },
  ];

  const addressRows = [
    { label: "ADDRESS", value: buildAddress() },
    { label: "TYPE", value: type },
    { label: "BEDROOMS", value: bedrooms },
    { label: "BATHROOMS", value: bathrooms },
    { label: "SQUARE FOOTAGE", value: addCommas(sqrfeet || 0) },
  ];

  return (
    <div className="h-full w-full px-4 text-xs xs:text-sm md:text-base ">
      {isFetchingNote ? (
        <div className="flex justify-center items-center w-full h-screen">
          <div className="bg-base-300 rounded-lg px-4 py-1 mb-48">
            <LoadingTable label="Note" />
          </div>
        </div>
      ) : (
        <div className="w-full flex flex-col  gap-4 items-center justify-center">
          <div className="w-full md:w-1/2">
            <div className="text-brown flex gap-1.5 items-center justify-center text-sm sm:text-lg md:text-xl lg:text-2xl font-semibold mb-3 ">
              <FontAwesomeIcon icon={faLocationDot} className="" />
              <span>{buildAddress()}</span>
            </div>
            <div className="mb-3">
              <PropertyImageCarousel images={note.images} />
            </div>
            <div className="flex justify-between items-center mb-2">
              <div className="">
                <ActionButton
                  onClick={currentUser ? handleDownloadClick : handleRegister}
                  label={"SEE SELLER DETAILS"}
                />
              </div>
              {currentUser && (
                <div className="mr-2">
                  <SavedNoteButton note={note} />
                </div>
              )}
            </div>
            <div className="mb-3">
              <NoteInfoCard header={"INVESTMENT SUMMARY"}>
                <div className="w-full">
                  <table className="w-full mt-3">
                    <thead className="border-b">
                      <tr>
                        <td className="text-main-gray font-bold py-2 px-1">
                          TYPE
                        </td>
                        <td className="text-main-gray font-bold py-2 px-1">
                          UNDERLYING ASSET
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="bg-gray-100">
                        <td className="text-main-gray font-medium py-2 px-1">
                          {loan_type}
                        </td>
                        <td className="text-main-gray font-medium py-2 px-1">
                          {type}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="px-4">
                  <table className="w-full mt-3 border ">
                    <tbody>
                      {highlights.map((highlight: Highlight) => (
                        <tr key={highlight.id} className="border-b">
                          <td className="text-main-gray text-sm font-medium px-2 py-2">
                            {highlight.name}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </NoteInfoCard>
            </div>
            <div className="mb-3">
              <NoteInfoCard header={"OVERVIEW"}>
                <div className="w-full">
                  <table className="w-full mt-3 border">
                    <tbody>
                      {overviewRows.map((row, index) => (
                        <tr key={index} className="border-b">
                          <td className="text-main-gray font-medium py-2 px-1 border-r pl-3">
                            {row.label}
                          </td>
                          <td className="text-main-gray font-medium py-2 pl-3">
                            {row.value}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </NoteInfoCard>
            </div>
            <div className="mb-3">
              <NoteInfoCard header={"ABOUT THE PROPERTY"}>
                <div className="w-full pt-4">
                  <div className="">
                    <iframe
                      title="Property Map"
                      width="100%"
                      height="300px"
                      src={`https://maps.google.com/maps?q=${encodeURIComponent(
                        buildAddress()
                      )}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
                    ></iframe>
                  </div>
                  <div className="font-bold text-main-gray my-2">Details</div>
                  <table className="w-full mt-1 border">
                    <tbody>
                      {addressRows.map((row, index) => (
                        <tr key={index} className="border-b">
                          <td className="text-main-gray font-medium py-2 px-1 border-r pl-3">
                            {row.label}
                          </td>
                          <td className="text-main-gray font-medium py-2 pl-3">
                            {row.value}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </NoteInfoCard>
              <div className="mt-4">
                <div
                  className="flex justify-start items-center gap-2 cursor-pointer"
                  onClick={() => navigate("/")}
                >
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    className="text-main-gray text-xl"
                  />
                  <span className="text-main-gray font-bold text-lg">
                    BACK TO NOTES
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="w-full md:w-5/12">
              {note.status === "Avaliable" || note.status === "Available" ? (
                <ActionFormWrapper
                  title={"REQUEST INFORMATION"}
                  hook={
                    <span className="italic">
                      THIS NOTE IS
                      <span className="font-bold"> AVAILABLE </span>
                      FOR PURCHASE
                    </span>
                  }
                >
                  <RequestInformationForm note={note} />
                </ActionFormWrapper>
              ) : (
                <ActionFormWrapper
                  title={"SOLD!"}
                  hook={
                    <span className="italic">
                      THIS NOTE IS
                      <span className="font-bold"> NOT AVAILABLE </span>
                      FOR PURCHASE
                    </span>
                  }
                >
                  <NoteSoldForm />
                </ActionFormWrapper>
              )}
            </div> */}
        </div>
      )}
    </div>
  );
};

export default Note;
