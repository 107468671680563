import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import useSwiper from "../../../hooks/useSwiper";
import useAuth from "../../../hooks/useAuth";
import { userApi } from "../../../api";
import useAlert from "../../../hooks/useAlert";
import useModal from "../../../hooks/useModal";
import LoginSlide from "./LoginSlide";
import RegisterSlide from "./RegisterSlide";
import PasswordSlide from "./PasswordSlide";
import "swiper/css";
import ContactSlide from "./ContactSlide";
import { FullNote } from "../../../interfaces/mortgageNote";
import SellerDetailsModal from "../SellerDetailsModal";

interface LoginRegisterModalProps {
  initialSlide?: number;
  note?: FullNote;
}

export interface HubspotContactData {
  firstName: string;
  lastName: string;
  phone: string;
}

export interface HubspotContactErrors {
  firstName: string;
  lastName: string;
  phone: string;
}

export interface PasswordFormErrors {
  password: string;
  repeatPassword: string;
}

const LoginRegisterModal: React.FC<LoginRegisterModalProps> = ({
  initialSlide = 0,
  note,
}) => {
  const { onSwiper, goToNextSlide, goToPrevSlide, onSlideChange, activeIndex } =
    useSwiper({ initialSlide });

  const { login, isLoading, register } = useAuth();

  const { setAlert } = useAlert();

  const { setShowModal, openModalWith } = useModal();

  const [isLoadingLocal, setIsLoadingLocal] = useState<boolean>(false);

  const [hubspotID, setHubspotID] = useState<string | null>(null);

  const [needsContact, setNeedsContact] = useState<boolean>(false);

  interface EmailFormErrors {
    email: string;
  }

  const [emailErrors, setEmailErrors] = useState<EmailFormErrors>({
    email: "",
  });

  interface LoginFormErrors {
    email: string;
    password: string;
  }

  const [contactErrors, setContactErrors] = useState<HubspotContactErrors>({
    firstName: "",
    lastName: "",
    phone: "",
  });

  const [loginErrors, setLoginErrors] = useState<LoginFormErrors>({
    email: "",
    password: "",
  });

  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const [passwordData, setPasswordData] = useState({
    password: "",
    repeatPassword: "",
  });

  const [passwordErrors, setPasswordErrors] = useState<PasswordFormErrors>({
    password: "",
    repeatPassword: "",
  });

  const [contactData, setContactData] = useState<HubspotContactData>({
    firstName: "",
    lastName: "",
    phone: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setLoginData((prev) => ({ ...prev, [name]: value }));
  };

  const [email, setEmail] = useState("");

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailErrors((prev) => ({ ...prev, email: "Invalid email" }));
      return false;
    }
    return true;
  };

  const submitEmailHubspot = async () => {
    setIsLoadingLocal(true);
    setEmailErrors({ email: "" });

    // Validate the email before making the API call
    if (!validateEmail(email)) {
      setEmailErrors({ email: "Invalid email format" });
      setIsLoadingLocal(false);
      return;
    }

    try {
      // Call the API to check if the email exists in HubSpot
      const resp:
        | {
            hubspotExists: boolean;
            hubspotID?: string;
            first_name?: string;
            last_name?: string;
            phone?: string;
          }
        | { alreadyRegistered: boolean } = await userApi.checkHubspotEmail(
        email
      );

      if ("alreadyRegistered" in resp && resp.alreadyRegistered) {
        setLoginData((prev) => ({ ...prev, email }));
        goToPrevSlide();
      } else if (
        "hubspotExists" in resp &&
        resp.hubspotExists &&
        resp.hubspotID
      ) {
        setHubspotID(resp.hubspotID);
        setContactData({
          firstName: resp.first_name || "",
          lastName: resp.last_name || "",
          phone: resp.phone || "",
        });
        goToNextSlide();
      } else {
        setNeedsContact(true);

        goToNextSlide();
      }
    } catch (error) {
      console.error("Error checking email with HubSpot:", error);
      setEmailErrors({ email: "An error occurred. Please try again later." });
    } finally {
      // Ensure loading state is reset in all cases
      setIsLoadingLocal(false);
    }
  };

  const validatePassword = (password: string, repeatPassword: string) => {
    if (password.length < 8) {
      setPasswordErrors((prev) => ({
        ...prev,
        password: "Password must be at least 8 characters",
      }));
      return false;
    }
    if (password !== repeatPassword) {
      setPasswordErrors((prev) => ({
        ...prev,
        repeatPassword: "Passwords do not match",
      }));
      return false;
    }
    return true;
  };

  const submitPassword = async () => {
    if (!validatePassword(passwordData.password, passwordData.repeatPassword)) {
      return;
    }
    if (hubspotID) {
      setIsLoadingLocal(true);
      try {
        const { success, error } = await register({
          email: email.trim().toLocaleLowerCase(),
          password: passwordData.password,
          hubspotID: Number(hubspotID),
          first_name: contactData.firstName,
          last_name: contactData.lastName,
          phone: contactData.phone,
        });
        if (success) {
          setShowModal(false);
          setAlert({
            display: true,
            message: "You've been registered successfully",
            type: "info",
          });
        } else {
          setAlert({
            display: true,
            message: error,
            type: "error",
          });
        }
      } catch (error) {
        console.error("Error registering user:", error);
      } finally {
        setIsLoadingLocal(false);
        if (note) {
          openModalWith({
            title: "",
            body: <SellerDetailsModal note={note} />,
            hideButtons: true,
          });
        }
      }
    }
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPasswordData((prev) => ({ ...prev, [name]: value }));
  };

  const submitLogin = async () => {
    try {
      const token: any = await login(
        loginData.email.trim().toLowerCase(),
        loginData.password
      );
      if (token && token.length > 0) {
        setShowModal(false);
        setAlert({
          display: true,
          message: "You've been logged in successfully",
          type: "info",
        });
      } else {
        setLoginErrors((prev) => ({
          ...prev,
          password: "Invalid email or password",
        }));
      }
    } catch (error) {
      console.error("Error logging in user:", error);
    } finally {
      if (note) {
        openModalWith({
          title: "",
          body: <SellerDetailsModal note={note} />,
          hideButtons: true,
        });
      }
    }
  };

  const handleContactChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setContactData((prev) => ({ ...prev, [name]: value }));
  };

  const submitContactData = async () => {
    if (!validateContact(contactData)) {
      return;
    }
    setIsLoadingLocal(true);
    try {
      const resp = await userApi.createHubspotContact({
        ...contactData,
        email,
      });
      setHubspotID(resp.id);
      goToNextSlide();
    } catch (error) {
      console.error("Error creating Hubspot contact:", error);
    } finally {
      setIsLoadingLocal(false);
    }
  };

  const validateContact = (contactData: HubspotContactData) => {
    if (!contactData.firstName) {
      setContactErrors((prev) => ({
        ...prev,
        firstName: "First name is required",
      }));
      return false;
    }
    if (!contactData.lastName) {
      setContactErrors((prev) => ({
        ...prev,
        lastName: "Last name is required",
      }));
      return false;
    }
    if (!contactData.phone) {
      setContactErrors((prev) => ({
        ...prev,
        phone: "Phone number is required",
      }));
      return false;
    }
    return true;
  };

  return (
    <div className="login-register-modal flex">
      <Swiper
        onSwiper={onSwiper}
        onSlideChange={onSlideChange}
        initialSlide={initialSlide}
        allowTouchMove={false}
        spaceBetween={40}
      >
        <SwiperSlide>
          <LoginSlide
            loginErrors={loginErrors}
            loginData={loginData}
            handleChange={handleChange}
            submitLogin={submitLogin}
            goToNextSlide={goToNextSlide}
            isLoading={isLoading}
          />
        </SwiperSlide>

        <SwiperSlide>
          <RegisterSlide
            emailErrors={emailErrors}
            email={email}
            setEmail={setEmail}
            submitEmailHubspot={submitEmailHubspot}
            isLoadingEmail={isLoadingLocal}
            goToPrevSlide={goToPrevSlide}
          />
        </SwiperSlide>

        {needsContact && (
          <SwiperSlide>
            <ContactSlide
              contactData={contactData}
              contactErrors={contactErrors}
              handleChange={handleContactChange}
              submitData={submitContactData}
              goToNextSlide={goToNextSlide}
              isLoading={isLoadingLocal}
            />
          </SwiperSlide>
        )}

        <SwiperSlide>
          <PasswordSlide
            passwordData={passwordData}
            passwordErrors={passwordErrors}
            handlePasswordChange={handlePasswordChange}
            submitPassword={submitPassword}
            isLoading={isLoadingLocal}
          />
        </SwiperSlide>
      </Swiper>
      {/* <p>Current slide: {activeIndex + 1}</p> */}
    </div>
  );
};

export default LoginRegisterModal;
