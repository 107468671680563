/* eslint-disable import/no-anonymous-default-export */
import Note from "../interfaces/mortgageNote";
import { CreateNoteParams } from "../pages/private/NoteEditNew";
import { EditNoteParams } from "../pages/private/NoteEditNew/Edit";
import axios from "../utils/axios";

class AdminApi {
 
  async createNote(data: CreateNoteParams): Promise<Note> {
    const formDataToSend = new FormData();

    Object.entries(data).forEach(([key, value]) => {
      if (
        value &&
        typeof value === "object" &&
        "file" in value &&
        value.file instanceof File
      ) {
        formDataToSend.append(key, value.file, value.fileName);
      } else if (Array.isArray(value)) {
        value.forEach((item, index) => {
          if (
            item &&
            typeof item === "object" &&
            "file" in item &&
            item.file instanceof File
          ) {
            formDataToSend.append(`${key}[${index}]`, item.file, item.fileName);
            formDataToSend.append(`${key}[${index}].alt`, item.alt);
          } else {
            formDataToSend.append(`${key}[]`, item);
          }
        });
      } else {
        formDataToSend.append(key, String(value));
      }
    });

    // Use `FormData` instead of a plain object for the request body
    const resp = await axios.post<Note>(
      `/api/mortgageNotes/admin/create-note`,
      formDataToSend,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return resp.data;
  }

  async updateNote(id: number, data: EditNoteParams): Promise<Note> {
    const formDataToSend = new FormData();

    Object.entries(data).forEach(([key, value]) => {
      if (
        value &&
        typeof value === "object" &&
        "file" in value &&
        value.file instanceof File
      ) {
        formDataToSend.append(key, value.file, value.fileName);
      } else if (Array.isArray(value)) {
        value.forEach((item, index) => {
          if (
            item &&
            typeof item === "object" &&
            "file" in item &&
            item.file instanceof File
          ) {
            formDataToSend.append(`${key}[${index}]`, item.file, item.fileName);
            formDataToSend.append(`${key}[${index}].alt`, item.alt);
          } else {
            formDataToSend.append(`${key}[]`, item);
          }
        });
      } else {
        formDataToSend.append(key, String(value));
      }
    });

    const resp = await axios.post<Note>(
      `/api/mortgageNotes/admin/update-note/${id}`,
      formDataToSend,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return resp.data;
  }

  async deleteNote(id: number): Promise<any> {
    const resp = await axios.delete(
      `/api/mortgageNotes/admin/delete-note/${id}`
    );

    return resp.data;
  }
}

export default new AdminApi();
