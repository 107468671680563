import React, { useCallback, useEffect, useState } from "react";
import logo from "../../assets/images/IncomeNotesLogo.png";
import useAuth from "../../hooks/useAuth";
import PrivateNav from "./PrivateNav";
import PublicNav from "./PublicNav";
import { debounce } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { handleSearchAndFetch } from "../../redux/slices/mortgageNotesSlice";
import { motion, AnimatePresence } from "framer-motion";

const HeaderNav = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  // Get searchQuery from Redux store
  const searchQuery = useSelector(
    (state: RootState) => state.mortgageNotes.searchQuery
  );
  const [localSearchQuery, setLocalSearchQuery] = useState(searchQuery);

  // Memoize the debounced search function
  const debouncedSearch = useCallback(
    debounce((query: string) => {
      dispatch(handleSearchAndFetch(query));
    }, 300),
    [dispatch]
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    setLocalSearchQuery(query); // Update local state immediately
    debouncedSearch(query); // Debounce the Redux update
  };

  // Sync with URL params only when location.search changes
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const query = params.get("search") || "";
    setLocalSearchQuery(query);
  }, [location.search]);

  // Sync with Redux state when it changes
  useEffect(() => {
    setLocalSearchQuery(searchQuery);
  }, [searchQuery]);

  const isHomePage = location.pathname === "/";
  const isNotePage = location.pathname.startsWith("/notes/");

  return (
    <div className="fixed top-0 left-0 right-0 w-full z-50 navbar bg-brown shadow-md flex items-center justify-end pl-2 pr-0">
      <div className="h-full w-full flex items-center justify-end">
        <div className="flex-1 cursor-pointer" onClick={() => navigate("/")}>
          <img src={logo} alt="logo" className="max-w-[120px] min-w-[120px]" />
        </div>
        <AnimatePresence mode="wait">
          {isHomePage && (
            <motion.div
              key="search"
              initial={{ opacity: 0, width: 0 }}
              animate={{ opacity: 1, width: "auto" }}
              exit={{ opacity: 0, width: 0 }}
              transition={{ duration: 0.25 }}
              className="form-control invisible sm:visible z-0"
            >
              <input
                type="text"
                placeholder="Search for an address"
                className="input input-bordered border-gray-300 focus:border-gray-300 bg-white text-black w-full sm:w-72 md:w-96"
                value={localSearchQuery}
                onChange={handleSearchChange}
              />
            </motion.div>
          )}
          {isNotePage && (
            <motion.button
              key="back"
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -20 }}
              transition={{ duration: 0.2 }}
              onClick={() => navigate(-1)}
              className="btn btn-ghost z-0"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M10 19l-7-7m0 0l7-7m-7 7h18"
                />
              </svg>
              Back To Notes
            </motion.button>
          )}
        </AnimatePresence>
      </div>
      <div className="z-50 bg-brown h-full">
        <div className="px-2">{currentUser ? <PrivateNav /> : <PublicNav />}</div>
      </div>
    </div>
  );
};

export default HeaderNav;
