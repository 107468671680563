import React from "react";
import { FullNote } from "../../interfaces/mortgageNote";
import { faCopy } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import formatPhoneNumber from "../../helpers/formatPhoneNumber";

interface SellerDetailsModalProps {
  note: FullNote;
}

const SellerDetailsModal = ({ note }: SellerDetailsModalProps) => {
  interface SellerDetails {
    seller?: string;
    company?: string;
    phone?: string;
    email?: string;
  }

  const { street_number, city, state, postal_code } = note;

  const {
    first_name,
    last_name,
    email,
    phone,
    company,
    // company_image,
    display_company,
    display_email,
    display_phone,
    display_name,
  } = note.user;

  const sellerDetails: SellerDetails = {
    ...(display_name && { seller: `${first_name} ${last_name}` }),
    ...(display_company && { company }),
    ...(display_phone && { phone: formatPhoneNumber(phone) }),
    ...(display_email && { email }),
  };

  const buildAddress = (): string =>
    `${street_number} ${city}, ${state} ${postal_code}`;

  const emailLink = `mailto:${
    sellerDetails.email
  }?subject=I am interested in the Mortgage Note on ${buildAddress()}`;

  const phoneLink = `tel:${sellerDetails.phone}`;

  return (
    <>
      <div className="w-full flex flex-col items-center justify-center px-12 -mt-10 ">
        <div className="border-2 border-main-gray rounded-lg ">
          <table className="table">
            <tbody className="text-left text-main-gray">
              {Object.entries(sellerDetails).map(([key, value]) => (
                <tr key={key} className=" round">
                  <td className="font-bold">
                    {key.charAt(0).toUpperCase() + key.slice(1)}
                  </td>
                  <td className="flex items-center justify-between">
                    {key === "phone" ? (
                      <a
                        href={phoneLink}
                        target="_blank"
                        rel="noreferrer"
                        className="hover:underline hover:text-secondary"
                      >
                        {value}
                      </a>
                    ) : key === "email" ? (
                      <a
                        href={emailLink}
                        target="_blank"
                        rel="noreferrer"
                        className="hover:underline hover:text-secondary"
                      >
                        {value}
                      </a>
                    ) : (
                      <span>{value}</span>
                    )}
                    <button className="btn btn-icon btn-sm btn-ghost hover:bg-gray-200">
                      <FontAwesomeIcon icon={faCopy} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default SellerDetailsModal;
