import React from "react";
import { PropertyImage } from "../../interfaces/mortgageNote";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./PropertyImageCarousel.css";

interface PropertyImageCarouselProps {
  images: PropertyImage[];
}

const PropertyImageCarousel = ({ images }: PropertyImageCarouselProps) => {
  // Sort images by order_position
  const sortedImages = [...images].sort(
    (a, b) => a.order_position - b.order_position
  );

  return (
    <div className="w-full ">
      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={10}
        slidesPerView={1}
        loop={true}
        autoplay={true}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        pagination={{ clickable: true, el: ".swiper-pagination" }}
      >
        {sortedImages.map((image) => (
          <SwiperSlide key={image.id}>
            <img
              src={image.link}
              alt={image.name}
              className="w-full h-96 object-cover rounded-[6px]"
            />
          </SwiperSlide>
        ))}

        {/* Custom navigation buttons */}
        <div
          style={{
            color: "white",
          }}
          className="swiper-button-prev text-white text-3xl absolute left-4 top-1/2 transform -translate-y-1/2 cursor-pointer z-10 opacity-35 hover:opacity-100"
        ></div>
        <div
          style={{
            color: "white",
          }}
          className="swiper-button-next text-white text-3xl absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer z-10 opacity-35 hover:opacity-100"
        ></div>

        {/* Custom pagination dots */}
        <div className="swiper-pagination mt-4"></div>
      </Swiper>
    </div>
  );
};

export default PropertyImageCarousel;
